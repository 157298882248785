footer {
    background: var(--color-background-new);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    /* margin-top: 7rem; */
}

/* .footer a {
    color: var(--color-bg);
} */

/* .footer__logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
} */

/* .permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
} */

.footer__copyright {
    margin-bottom: 4rem;
    color: var(--color-text-new);
}

/* -------------------------- MEDIA QUERIES (FOR SMALL DEVICES LIKE PHONES) -------------------------- */

@media screen and (max-width: 600px) {
        .permalinks {
            flex-direction: column;
            gap: 1.5rem;
        }
}

/* changes made */