#portfolio {
    background-color: var(--color-orange-light);
    margin-top: 0;
    padding: 8rem 0;
}

.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem;
}

.portfolio__item {
    overflow: hidden;
    transition: var(--transition);
    /* border: 3px solid var(--color-orange-light); */
    border-radius: 50px;
    background: var(--color-orange);
    box-shadow: 20px 20px 40px #ce5a02,
                -20px -20px 40px #ff7a02;
}

.portfolio__item img {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.portfolio__item:hover {
    transform: scale(1.10);
    box-shadow: 0 8px 16px var(--color-orange);
}

.portfolio__item-cta {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    padding: 20px;
    box-sizing: border-box;
    background-color: var(--color-orange);
    transform: translateY(100%);
    transition: var(--transition);
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    opacity: 0;

    & .btn-primary {
        background-color: var(--color-blue);
    }
}

.portfolio__item:hover .portfolio__item-cta {
    transform: translateY(0);
    opacity: 1;
}

.portfolio__item h3 {
    padding-left: 10px;
    background: var(--color-corn);
    border-radius: 10px;
    margin: 10px;
    font-size: 20px;
    color: var(--color-blue);
    font-weight: 700;
}

.portfolio__item .portfolio__item-description {
    padding-left: 10px;
    margin: 10px;
    font-size: 12px;
    color: var(--color-corn);
    line-height: 2;
}

.portfolio__item .btn {
    margin-right: 10px;
}

/* -------------------------- MEDIA QUERIES (FOR MEDIUM DEVICES LIKE TABLETS) -------------------------- */

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* -------------------------- MEDIA QUERIES (FOR SMALL DEVICES LIKE PHONES) -------------------------- */

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}