#contact {
    background-color: var(--color-background-new);
    margin-top: 0;
    padding-top: 8rem;
}

.contact__container {
    display: grid;
    grid-template-columns: 40% 70%;
    padding-top: 3rem;
}

.contact__prompt {
    color: var(--color-corn);
    margin-top: 4rem;

    & p {
        margin-top: 1rem;
    }
}

.contact__links {
    margin-top: 1rem;

    & a {
        color: var(--color-orange);
        font-size: 2.5rem;
        padding-right: 0.25rem;
    }
}


/* -------------------------- FORM -------------------------- */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin-left: 5rem;
}

input, textarea {
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-corn);
    resize: none;
    color: var(--color-text-new);
}

/* -------------------------- MEDIA QUERIES (FOR MEDIUM DEVICES LIKE TABLETS) -------------------------- */

@media screen and (max-width: 1024px) {
    #contact {
        padding-top: 0;
    }


    .contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    form {
        margin-left: 0;
    }
}

/* -------------------------- MEDIA QUERIES (FOR SMALL DEVICES LIKE PHONES) -------------------------- */

@media screen and (max-width: 600px) {
    #contact {
        padding-top: 0;
    }

    .contact__container {
        width: var(--container-width-sm);
    }

    form {
        margin-left: 0;
        
    }
}