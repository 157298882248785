@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500;700&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #F9F6F0;
    /* --color-bg: #e6eae9; */
    --color-bg-variant: #dedfde;
    --color-primary: #3a3134;
    --color-primary-variant: #d0d6d6;
    --color-accent: #76656a;
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);
    --color-text: #0e1110;

    /* new colors */
    --color-background-new: #0C0C53;
    --color-orange: #F26A02;
    --color-orange-light: #F4842E;
    --color-orange-dark: #dc6002;
    --color-blue: #000080;
    --color-blue-accent: #4747FF;
    --color-corn: #FFFAE3;
    --color-text-new: #FFFAE3;



    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

/* removing scrollbar */
::-webkit-scrollbar { 
    display: none;
}

body {
    font-family: 'IBM Plex Mono', monospace;
    background: var(--color-bg);
    color: var(--color-text-new);
    /* color: var(--color-white); */
    line-height: 1.7;
}

/* -------------------------- GENERAL STYLES -------------------------- */

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-accent);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-accent);
}

a {
    color: var(--color-primary);
    transition: var(--transition)
}

a:hover {
    color: var(--color-corn);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-orange);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-orange);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-corn);
    color: var(--color-blue);
    border-color: transparent;
}

.btn-primary {
    background-color: var(--color-orange);
    color: var(--color-corn);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* -------------------------- MEDIA QUERIES (FOR MEDIUM DEVICES LIKE TABLETS) -------------------------- */

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/* -------------------------- MEDIA QUERIES (FOR SMALL DEVICES LIKE MOBILE PHONES) -------------------------- */

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-bottom: 2rem;
    }
}
