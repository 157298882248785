#about {
    margin-top: 0;
    padding-top: 8rem;
    padding-bottom: 9rem;

    & h2 {
        color: var(--color-blue);
    }
}

section {
    background-color: var(--color-corn);
}

.about__container {
    display: grid;
    grid-template-columns: 35% 60%;
    gap: 10%;
}

.about__me {
    width: 100%;
    padding-right: 5rem;
    padding-bottom: 3rem;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    display: grid;
    place-items: center;
    /* adding the following styles for the skew effect */
    overflow: hidden;
    transform: skew(60deg, -30deg) scaleY(0.66667);
}

.text {
    width: 100%;
    height: 100%;
}

.row {
    width: 110%;
    height: 80px;
    line-height: 0.9;
    overflow: hidden;
    transform: skew(60deg, -30deg) scaleY(0.66667);
    border: 1px solid var(--color-blue);

    &:nth-child(2) {
        margin-left: 41px;
        /* transform: skew(0deg, -30deg) scaleY(1.33333); */
        transform: skew(-30deg, 0deg) scaleY(1.33333);
        opacity: 0.7;
    }

    &:nth-child(3) {
        margin-left: 82px;
    }
}

.row > p {
    margin: 0;
    height: 70px;
    line-height: 0.8;
    animation: text_move 6s infinite ease;
    text-align: center;
    color: var(--color-blue);
    padding-top: 2rem;
}

@keyframes text_move {
    0% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

.about__info {
    margin: 1.5rem 0 1.5rem 0;
    padding: 20px;
    border-radius: 1rem;
    border: 1px solid var(--color-blue);
}

.about__info p {
    margin-bottom: 10px;
    font-size: 0.85rem;
    color: var(--color-blue);
}

.about__content .btn-primary {
    margin-top: 2rem;
    color: var(--color-blue);
}

.about__content .btn-primary:hover {
    transition: var(--transition);
    background-color: var(--color-blue-accent);
    color: var(--color-text-new);
    border-color: var(--color-blue-accent);
}

/* -------------------------- MEDIA QUERIES (FOR MEDIUM DEVICES LIKE TABLETS) -------------------------- */

@media screen and (max-width: 1024px) {
    #about {
        padding-top: 3rem;
        padding-bottom: 4rem;
    }


    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    /* @keyframes text_move {
        0% {
            transform: translateY(-30%);
        }
    
        50% {
            transform: translateY(-130%);
        }
    
        100% {
            transform: translateY(-30%);
        }
    } */

    .about__me {
        /* width: 50%;
        margin: 2rem auto 4rem;
        padding-bottom: 1rem; */
        display: none;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }
}

/* -------------------------- MEDIA QUERIES (FOR SMALL DEVICES LIKE PHONES) -------------------------- */

@media screen and (max-width: 600px) {
    .about__me {
        display: none;
    }
    /* .wrapper {
        rotate: 15deg;
        margin-top: 2rem;
        margin-left: 1rem;
    }
    
    .row {
        width: 70%;
        height: 40px;
    } */

    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0;
    }
}